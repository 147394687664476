import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import { Formik } from 'formik';
import * as Yup from 'yup';

import Results from "./Results";
import "./Create.scss";

const validationSchema = Yup.object().shape({
  Name: Yup.string().trim().min(2).max(32).required(),
  JobTitle: Yup.string().trim().min(2).max(32).required(),
  Company: Yup.string().trim().min(2).max(32).required(),
  City: Yup.string().trim().min(2).max(32).required(),
  Email: Yup.string().trim().email().required(),
  LinkedIn: Yup.string().trim().matches(/^((?!www\.linkedin\.com).)*$/, "Only enter the profile id, not the full URL").min(2).max(32).required(),
});

class Create extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      submitted: false,
      passUrl: "",
    };
  }
  
  render() {
    const issuePass = (values) => {
      const body = {
        "name": values.Name.trim(),
        "email": values.Email.trim(),
        "title": values.JobTitle.trim(),
        "company": values.Company.trim(),
        "city": values.City.trim(), 
        "liurl": "https://www.linkedin.com/in/" + values.LinkedIn.trim().replace(/^\/|\/$/g, '')
      };
      this.setState({submitted: true, loading: true});

      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json, */*; q=0.01");
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Authorization", "GDeQJYFFaqCRPgTQpDuKmYbOngKASqzSppWGiCUUHrfgJQoqQyTrHwWdbOoCJoNk");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
      };
      fetch("https://api.passslot.com/v1/templates/4574217277014016/pass", requestOptions)
        .then(response => response.json())
        .then((result) => {
          if ("url" in result) {
            this.setState({submitted: true, loading: false, error: false, passUrl: String(result["url"]).replace("?t=", ".pkpass?t=")});
          } else {
            this.setState({submitted: true, loading: false, error: true})
          }
        })
        .catch(error => this.setState({submitted: true, loading: false, error: true}));
        
    };

    return (
      <Container>
        <Row>
          <Col className="create-box">
            <h3>Create a Digital Business Card &#x1FAAA;</h3>
            <p>Create a digital business card (featuring your LinkedIn profile) that you can add to your Apple Wallet. This tool was build for personal usage. So, use at your own risk. All passes are issues by <i>PassSlot</i>.</p>
            <hr></hr>
            <div className={this.state.submitted ? "d-none" : ""}>
              <Formik
                validationSchema={validationSchema}
                onSubmit={issuePass}
                initialValues={{
                  Name: '',
                  JobTitle: '',
                  Company: '',
                  City: '',
                  Email: '',
                  LinkedIn: '',
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Form.Group as={Col} md="7" lg="5" controlId="pcName">
                        <Form.Label className="formLabel">Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="Name"
                          placeholder="Jane Doe"
                          value={values.Name}
                          onChange={handleChange}
                          isInvalid={!!errors.Name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.Name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="7" lg="5" controlId="pcTitle">
                        <Form.Label className="formLabel">Job title</Form.Label>
                        <Form.Control
                          type="text"
                          name="JobTitle"
                          placeholder="Data Scientist"
                          value={values.JobTitle}
                          onChange={handleChange}
                          isInvalid={!!errors.JobTitle}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.JobTitle}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="7" lg="5" controlId="pcEmail">
                        <Form.Label className="formLabel">Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="Email"
                          placeholder="jane@example.com"
                          value={values.Email}
                          onChange={handleChange}
                          isInvalid={!!errors.Email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.Email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="7" lg="5" controlId="pcCompany">
                        <Form.Label className="formLabel">Company</Form.Label>
                        <Form.Control
                          type="text"
                          name="Company"
                          placeholder="Acme Limited"
                          value={values.Company}
                          onChange={handleChange}
                          isInvalid={!!errors.Company}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.Company}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="7" lg="5" controlId="pcCity">
                        <Form.Label className="formLabel">City</Form.Label>
                        <Form.Control
                          type="text"
                          name="City"
                          placeholder="London, UK"
                          value={values.City}
                          onChange={handleChange}
                          isInvalid={!!errors.City}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.City}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md="7" lg="5" controlId="pcLinkedIn">
                        <Form.Label className="formLabel">LinkedIn Profile</Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupLinkedIn">https://www.linkedin.com/in/</InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="jane-doe-ldn"
                            aria-describedby="inputGroupLinkedIn"
                            name="LinkedIn"
                            value={values.LinkedIn}
                            onChange={handleChange}
                            isInvalid={!!errors.LinkedIn}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.LinkedIn}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Button className="btnCreate" type="submit">Create Pass</Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
        <Row>
          <div className={this.state.loading && this.state.submitted ? "" : "d-none"}>
            Loading...
          </div>
          <div className={this.state.submitted && !this.state.loading ? "" : "d-none"}>
            <Results error={false} passUrl={this.state.passUrl} />
          </div>
        </Row>
      </Container>
    );
  }
}

export default Create;
