import './Results.scss';

import React from 'react';
import Alert from "react-bootstrap/Alert";
import addButton from "./img/addButton.svg";
import QRCode from "react-qr-code";

class Results extends React.Component {
  render() {
    if(this.props.error) {
      return(
        <div>
          <Alert variant="danger">
            <Alert.Heading>API Error</Alert.Heading>
            Something went wrong. Please try it again later.
          </Alert>
        </div>
      );
    }
    return(
      <div>
        <Alert variant="success">
          Scan the QR code on your iOS device or click the <i>Add to Apple Wallet</i> button if you are visiting this website from your phone.
        </Alert>
        
        <div className="qrBox">
          <QRCode value={this.props.passUrl} />
        </div>
        <a href={this.props.passUrl} target="_blank" rel="noreferrer"><img src={addButton} className="btnAdd" alt="Download Button" /></a>
      </div>
    );
  }
}

export default Results;
